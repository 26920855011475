
import { Component, Vue } from 'vue-property-decorator';
import CardCodeForm from '@/components/CardCodeForm.vue';

@Component({
  components: {
    CardCodeForm,
  },
})
export default class CardCode extends Vue {}
