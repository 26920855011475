

import { Component, Vue } from 'vue-property-decorator';
import { required, helpers } from 'vuelidate/lib/validators';
import giftCardService from '@/services/giftCards/giftCardService';
import authService from '@/services/auth/authService';

const codeRegex = helpers.regex('codeRegex', /^[a-z0-9]{6}-[a-z0-9]{6}$/);

@Component
export default class CardCodeForm extends Vue {
  public loading = false;
  public error = '';
  public form: any = {
    code: '',
  };

  validations(): any {
    return {
      form: {
        code: { required, codeRegex },
      },
    };
  }
  async recaptcha(): Promise<any> {
    if (!this.$v.invalid) {
      try {
        this.loading = true;
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('CodeValidation');
        const { data } = await authService.verifyCaptcha(token);
        if (data.success) {
          this.loading = false;
          await this.onCodeValid();
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
      } finally {
        this.loading = false;
      }
    }
  }
  async onCodeValid(): Promise<any> {
    const code: string = this.form.code.replace('-', '');
    if (!this.$v.invalid) {
      this.loading = true;
      try {
        const { data } = await giftCardService.checkValidity(code);
        this.$store.dispatch('cardModule/codeSuccess', { card: data });
        this.$router.push({
          name: 'CodeResult',
          query: { result: 'success', uuid: code },
        });
      } catch (e: any) {
        this.$router.push({
          name: 'CodeResult',
          query: { result: 'error' },
        });
        const { data } = e.response;
        const message = Array.isArray(data.message) ? data.message[0] : data.message;
        this.$store.dispatch('cardModule/codeFailure', { error: message });
      } finally {
        this.loading = false;
      }
    }
  }
}
